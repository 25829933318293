<div class="dialog">
    <div class="dialog_header">
        <button mat-icon-button class="close" [disabled]="loading" (click)="openConfirmDialog()"><svg
                xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
                <path id="ic_close"
                    d="M213.746-744.393l-6.441,6.436a1.459,1.459,0,0,1-1.063.457,1.406,1.406,0,0,1-1.046-.457,1.418,1.418,0,0,1-.45-1.054,1.449,1.449,0,0,1,.45-1.037l6.441-6.452-6.441-6.452a1.419,1.419,0,0,1-.441-1.037,1.473,1.473,0,0,1,.441-1.053,1.406,1.406,0,0,1,1.046-.457,1.485,1.485,0,0,1,1.063.441l6.441,6.452,6.458-6.452a1.431,1.431,0,0,1,1.046-.441,1.458,1.458,0,0,1,1.063.457,1.443,1.443,0,0,1,.433,1.053,1.448,1.448,0,0,1-.45,1.037l-6.441,6.452,6.441,6.452a1.419,1.419,0,0,1,.441,1.037,1.473,1.473,0,0,1-.441,1.054,1.406,1.406,0,0,1-1.046.457,1.406,1.406,0,0,1-1.046-.457Z"
                    transform="translate(-204.746 755.5)" fill="#999" />
            </svg>
        </button>
        {{ "companyAdminProfile.Request to withdraw money" | translate }}
    </div>
    <form [formGroup]="formGroup">

        <div class="dialog_content pb-0" mat-dialog-content *ngIf="!dataLoading">

            <div class="withdrawal-points">
                <p class="font-18 fontRegular text-color text-center py-2">
                    1 {{ 'companies.point' | translate }} = 1 {{'companyAdminProfile.Riyal' | translate }}
                </p>

                <div class="current-balance">
                    <h3>{{balance | number}}</h3>
                    <p>{{ 'companyAdminProfile.Your current balance' | translate }}</p>
                </div>

                <div class="input-points">
                    <h6>{{ 'companyAdminProfile.Amount to be withdrawn' | translate }}</h6>
                    <mat-form-field class="english-form-field">
                        <input matInput type="text" 
                        [maxlength]="6" 
                        formControlName="num_points" 
                        [pattern]="balancePattern">
                        <!-- <mat-error class="has-error" *ngIf="formGroup.controls['num_points'].value > balance">
                            {{ 'rewardedPoints.Maximum points' | translate:{balance: balance} }}
                        </mat-error> -->
                        <mat-error class="has-error arabic-form-field" *ngIf="hasError('num_points', 'max')">
                            {{ 'rewardedPoints.Maximum points' | translate:{balance: balance} }}
                        </mat-error>
                        <mat-error class="has-error arabic-form-field" *ngIf="hasError('num_points', 'required')">
                            {{'forms.Required' | translate }}
                        </mat-error>
                        <mat-error class="has-error arabic-form-field"
                            *ngIf="hasError('num_points', 'pattern') || hasError('num_points', 'min')">
                            {{ 'forms.Invalid value' | translate }}
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>

        </div>

        <div *ngIf='dataLoading' class="dataLoading">
            <mat-spinner class="m-auto" diameter="50"></mat-spinner>
        </div>

        <div class="dialog_actions" mat-dialog-actions>
            <button mat-button type="button" [disabled]="loading" class="btn-secondary"
                (click)="openConfirmDialog()">{{"general.Cancel"|translate}}</button>

            <button mat-button [disabled]="loading" [class.loading]="loading" (click)="saveRecord()"
                class="btn-primary">
                {{"companyAdminProfile.Submit a withdrawal requestt"| translate}}
            </button>
        </div>
    </form>
</div>