import { Injectable } from '@angular/core';
import { ApiServiceService } from '../../../../services/api-service.service';
import { BehaviorSubject, catchError, throwError } from 'rxjs';
import { environment } from '../../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class WithdrawalRequestsService {

  refreshList = new BehaviorSubject<boolean>(false);

  constructor(private _ApiService: ApiServiceService) { }


  getWithdrawalRequests(model: any) {
    return this._ApiService.get(`${environment.apiUrl}/money-withdraw-request/company`, model)
      .pipe(catchError(this.handleError));
  }

  getWithdrawalRequestDetails(id: string) {
    return this._ApiService.post(`${environment.apiUrl}/money-withdraw-request/request-details/?request_id=${id}`)
      .pipe(catchError(this.handleError));
  }
  
  sendWithdrawalRequest(data: any) {
    return this._ApiService.post(`${environment.apiUrl}/money-withdraw-request`, data)
      .pipe(catchError(this.handleError));
  }

  handleError(error: any) {
    return throwError(error);
  }
}
